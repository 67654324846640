<template>
    <div id="container">
        <nav-bar></nav-bar>
        <div id="loader" v-if="Loading">
          <loader-comp></loader-comp>
        </div>
        <div id="content" v-else>
            <div id="col1">
                <div id="col-header">
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="20" viewBox="0 0 11 20" fill="none" @click="back">
                        <g clip-path="url(#clip0_847_20516)">
                          <path d="M11 2.85714L4.125 10L11 17.1429L9.625 20L2.38419e-07 10L9.625 -9.53673e-07L11 2.85714Z" fill="#413F3F"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_847_20516">
                            <rect width="11" height="20" fill="white" transform="matrix(-1 0 0 -1 11 20)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    <h2>New requests</h2>
                </div>
                
                <div id="driver-container" v-if="Show">
                <driver-request v-for="value,index in Drivers" :key="index" :name="value.name" picture="https://firebasestorage.googleapis.com/v0/b/eat-express-32d51.appspot.com/o/ASSETS%2FPROFILE-1.png?alt=media&token=4f4850d0-35ed-4abe-a33d-4ce25a074dae" :id="value._id"></driver-request>
                </div>
                <div id="messages" v-else>
                  <empty-list v-if="Message==1" msg="No new requests"></empty-list>
                  <error-message v-if="Message==2" msg="Failed to fetch driver requests"></error-message>
                  <server-crash v-if="Message==3" msg="Oops something went wrong"></server-crash>
              </div>
            </div>
        <div id="divider"></div>
        <active-order></active-order>
        </div>
    </div>
</template>

<script>
import NavBar from '../../components/general/nav-bar.vue'
import ActiveOrder from '../../components/driver/side-map.vue'
import DriverRequest from '../../components/driver/driver-request.vue'
import EmptyList from '../../components/general/messages/no-items.vue'
import ErrorMessage from '../../components/general/messages/error-message.vue'
import ServerCrash from '../../components/general/messages/server-crash.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
export default {
    components:{NavBar,ActiveOrder,DriverRequest,EmptyList,ErrorMessage,ServerCrash,LoaderComp},
  data(){
    return{
      drivers:[],
      msg:0
    }
  },
  computed:{
    Drivers(){
       return this.drivers;   
  },
  Show(){
    return this.drivers.length>0
  },
  Message(){
    return this.msg
  },
  Loading(){
                return this.$store.getters['user/getLoader']
            }
  },
  methods:{
    back(){
        this.$router.go(-1)
    }
  },
  async mounted(){
    this.$store.commit('user/changeMenu',{value:2})
    this.$store.commit('user/setLoader',{value:true})
    let res = await this.$store.dispatch('driver/driverRequests')
    this.$store.commit('user/setLoader',{value:false})
    if(res.status==200){
      this.drivers = res.data
      if(this.drivers.length==0) {
        this.msg=1;
      }
    }
    else if(res.status==500){
      this.msg=3;
    }
    else{
           this.msg=1;
    }
  }
    
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#container{
    width:100vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y:auto;
    display: grid;
    grid-template-columns: 15vw 85vw;
  }
  #container::-webkit-scrollbar{
    display: none;
  }
  #content{
  width:85vw;
  height:100vh;
  overflow-x:hidden;
  overflow-y: auto;
  background:white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left:-1vw;
  display: grid;
  grid-template-columns: 57vw 3vw 25vw;
  }
  #content::-webkit-scrollbar{
  display: none;
  }
  #loader{
    width:85vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y: auto;
    background:white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-left:-1vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #divider{
  width:3.5px;
  height:fill;
  background: #D9D9D9;
  margin-top:10vh;
  margin-bottom:5vh;
  }
  #col-header{
    display: flex;
    align-items: center;
    margin-left:1vw;
    margin-top:2vh;
  }
  #col-header svg{
    margin-right:2vw;
    cursor: pointer;
}
#col-header h2{
    color: #413F3F;
font-family: Montserrat;
font-size: 22.093px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.375px;
}
#driver-container{
  height: auto;
  display: grid;
  grid-template-columns: repeat(2,25vw);
  gap:2vw;
  margin-top:3vh;
  padding-left:1.5vw;
  padding-bottom: 4vh;
  }
  #driver-container::-webkit-scrollbar{
    display: none;
  }
  #msg{
    text-align: center;
    font-family: Inter;
    font-size:22px;
    font-weight: 500;
    margin-top:5vh;
  }
  #messages{
    display:flex;
    align-items: center;
    justify-content: center;
  }
</style>