<template>
    <div id="message">
      <img :src="require('../../../assets/messages/success.png')" alt="" id="msg-image">
      <h2 id="msg-text">{{ msg }}</h2>
    </div>
  </template>
  
  <script>
  export default {
    props:['msg']
  }
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
  #message{
    border-radius: 43.555px;
  background: transparent;
  width:400px;
  height:350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  }
  #msg-image{
    width:200px;
    height:200px;
  }
  #msg-text{
  font-family: Inter;
  font-size:24px;
  font-weight: 600;
  color:rgba(0, 0, 0, 0.667);
  }
  </style>