export default {
  getSuspend(state) {
    return state.suspend;
  },
  getComp(state) {
    return state.comp;
  },
  getOutlets(state) {
    return state.outlets;
  },
  getCoordinates(state) {
    return state.center;
  },
};
