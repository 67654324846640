<template>
  <div>
    <div id="wrapper">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
    <div id="mobile-message">
      <img :src="require('./assets/messages/crashed.png')" alt="mobile error">
      <h2>EatExpress admin panel is not available in mobile devices</h2>
    </div>
    <div id="tablet-message">
      <img :src="require('./assets/messages/crashed.png')" alt="mobile error">
      <h2>EatExpress admin panel is not available in tablet devices</h2>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  async mounted(){
    if(!Cookies.get('authenticated')){
       this.$router.push({name:'login'})
    }
    this.$store.commit('user/setInitial')
    this.$store.commit('driver/setInitial')
    this.$store.commit('outlet/setInitial')
  //   await  navigator.geolocation.getCurrentPosition(
  //     position => {
  //        this.$store.commit('user/setCoordinates',{latitude:position.coords.latitude,longitude:position.coords.longitude})
  //        this.$store.commit('outlet/setCoordinates',{latitude:position.coords.latitude,longitude:position.coords.longitude})
  //        this.$store.commit('driver/setCenter',{latitude:position.coords.latitude,longitude:position.coords.longitude})
  //     },
  //     error => {
  //       console.log(error)
  //        window.alert("Please allow location permission to proceed further")
  //     },
  //  )
   let coords = await this.$store.dispatch('dashboard/getDrivers')
   this.$store.commit('dashboard/setDriverCoordinates',{value:coords})
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@media only screen and (max-width:480px){
  #wrapper{
    display: none;
  }
  #tablet-message{
    display: none;
  }
  #mobile-message{
    width:100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    flex-direction: column;
  }
  #mobile-message img{
    width:350px;
    height:320px;
  }
  #mobile-message h2{
    font-size:21px;
    font-family:Inter;
    font-weight: 500;
    text-align: center;
    width:80vw;
  }
}
@media only screen and (min-width:481px) and (max-width:960px){
  #wrapper{
    display: none;
  }
  #mobile-message{
    display: none;
  }
  #tablet-message{
    width:100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    flex-direction:column;
  }
  #tablet-message h2{
    font-size:24px;
    font-family:Inter;
    font-weight: 550;
    text-align: center;
    width:80vw;
  }
}
@media only screen and (min-width:961px){
  #wrapper{
    width:100vw;
    height:100vh;
    overflow-x: hidden;
    overflow-y:auto;
    position:fixed;
    left:0;
    top:0;
    margin:0px;
  }
  #wrapper::-webkit-scrollbar{
    display: none;
  }
  #mobile-message{
    display: none;
  }
  #tablet-message{
    display: none;
  }
}
</style>