import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const config = {
  apiKey: "AIzaSyD2bCpouqhLvwsRa49wlE1LKbHJkLifg2A",
  authDomain: "eat-express-32d51.firebaseapp.com",
  projectId: "eat-express-32d51",
  storageBucket: "eat-express-32d51.appspot.com",
  messagingSenderId: "52057041653",
  appId: "1:52057041653:web:014f0234a3598a94ad73bc",
  measurementId: "G-VFP56EJFQ3",
};

const firebaseApp = firebase.initializeApp(config);
export const db = firebaseApp.firestore();
