export default {
  setSuspend(state, payload) {
    state.suspend = payload.value;
  },
  setComp(state, payload) {
    state.comp = payload.value;
  },
  setOutlets(state, payload) {
    let val = {};
    for (let i = 0; i < payload.value.length; i++) {
      val[payload.value[i].restaurant_name] = {
        lat: parseFloat(
          parseFloat(payload.value[i].business_coordinatesX).toFixed(7)
        ),
        lng: parseFloat(
          parseFloat(payload.value[i].business_coordinatesY).toFixed(7)
        ),
      };
    }
    state.outlets = val;
  },
  setCoordinates(state, payload) {
    state.center = {
      lat: parseFloat(payload.latitude),
      lng: parseFloat(payload.longitude),
    };
  },
  setInitial(state) {
    state.center = {
      lat: 53.799999,
      lng: -1.751711,
    };
  },
};
