<template>
    <div id="suspend-comp">
        <div id="suspend-header">
            <h2>{{Title}}</h2>
            <div id="close-button" @click="cancelSuspension">X</div>
        </div>
        <div id="suspend-details">
         <img :src="Image" :alt="name" v-if="Image">
         <img src="https://firebasestorage.googleapis.com/v0/b/eat-express-32d51.appspot.com/o/ASSETS%2FPROFILE-1.png?alt=media&token=4f4850d0-35ed-4abe-a33d-4ce25a074dae" :alt="name" v-else>
         <div id="details-text">
            <h2>{{ name }}</h2>
            <!-- <h3>Warnings {{ warnings }}</h3> -->
         </div>
        </div>
        <div id="warning" v-if="suspended==false">
            <h2><span id="strong">{{ para.val1 }}</span>{{ para.val2 }}</h2>
        </div>
        <div v-if="Delete!=0" style="margin-top:1.6vh">
        <input type="password" :class="Error?'input-error':'input-1'"  placeholder="Enter password" v-model="password">
        <h2 class="error-message" v-if="Error">{{ ErrorMessage }}</h2>
        </div>
        <div id="suspend-button" @click="submit">{{ button }}</div>
        <div id="loader" v-if="Loading">
            <loader-comp></loader-comp>
        </div>
    </div>
</template>

<script>
import LoaderComp from '../general/loader-comp.vue'
export default {
    components:{LoaderComp},
    props:['image','name','suspended'],
    data(){
        return{
            internalLoader:false,
            error:false,
            password:'',
            error_message:''
        }
    },
    methods:{
        cancelSuspension(){
            if(this.suspended==false){
                this.$store.commit('driver/setSuspended',{value:false})
            }
        },
        async suspend(){
            this.internalLoader=true;
            let res = await this.$store.dispatch('driver/suspendDriver',{value:this.$route.params.id})
            this.internalLoader=false;
            if(res.status==200){
                let value = this.$store.getters['driver/getSuspended']
                if(value==true)
                {
                    this.$store.commit('driver/setSuspended',{value:false})
                    location.reload()
                }
                else
                {
                    this.$store.commit('driver/setSuspended',{value:true})
                    location.reload()
                }
            }
            else if(res.status==500){
                alert('Oops something went wrong at our end please try after some time')
            }
            else{
                alert('Operation Failed')
            }
        },
        async delete(){
            if(this.password==0){
                this.error=true;
                this.error_message="Please enter your password first"
            }
            this.internalLoader=true;
            let res = await this.$store.dispatch('driver/deleteDriver',{id:this.$route.params.id,password:this.password})
            this.internalLoader=false;

            if(res==200){
                alert('driver deleted')
                setTimeout(()=>{
                    this.$router.push({name:'drivers'})
                },1500)
            }
            else if(res==401){
                this.error=true;
                this.error_message="Please enter correct password"
            }
            else if(res==500){
                alert('Oops something went wrong at our end please try after some time')
            }
            else{
                alert('Operation Failed')
            }
        },
        async submit(){
            let val = this.$store.getters['driver/getComp']
            if(val==0) await this.suspend()
            else await this.delete()
        }
    },
    computed:{
        Image(){
            return this.image
        },
        Title(){
            let val = this.$store.getters['driver/getComp']
            if(val==0){
                if(this.suspended==true)
                return 'Enable Account'
                else
                return 'Suspend Account'
            }
            else{
                return  'Delete Account'
            }
        },
        para(){
            let val = this.$store.getters['driver/getComp']
            if(val==0)
            return {val1:"This account will be suspended ",val2:"until you enable it"}
           else return {val1:"This account will be deleted ",val2:"forever"}
        },
        button(){
            let val = this.$store.getters['driver/getComp']
            if(val == 0){
                if(this.suspended==true){
                    return 'Enable'
                }
                else return 'Suspend'
            }
            else return 'Delete'
        },
        Loading(){
                return this.internalLoader
            },
            Delete(){
                return this.$store.getters['driver/getComp']
            },
            Error(){
                return this.error
            },
            ErrorMessage(){
                return this.error_message
            }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#suspend-comp{
    width:32vw;
    padding-top:7vh;
    padding-left:15vw;
}
#suspend-header{
    padding-left:3vw;
    display: flex;
    align-items: center;
}
#suspend-header h2{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
#close-button{
    width: 30px;
height: 30px;
flex-shrink: 0;
border-radius: 4px;
background: #D9D9D9;
display: flex;
align-items: center;
justify-content: center;
color: #000;
text-align: center;

/* Sub text */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
margin-left:10vw;
}
#suspend-details{
    display: grid;
    display: flex;
    padding-left:2vw;
    padding-top:4vh;
}
#suspend-details img{
    width:100px;
    height:100px;
    border-radius: 100px;;
}
#details-text{
    margin-left:10px;
}
#details-text h2{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
#details-text h3{
    color: #413F3F;
font-family: Inter;
font-size: 17.548px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.27px;
}
#warning{
    padding-left:2vw;
    margin-top:6vh;
}
#strong{
    color: #171717;
text-align: justify;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
}
#warning h2{
    color: #413F3F;
text-align: justify;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
#suspend-button{
    border-radius: 8px;
background: #25AD34;
width:20vw;
margin-left:8vw;
cursor: pointer;
height:50px;
margin-top:6vh;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
display: flex;
align-items: center;
justify-content: center;
}
#loader{
    width:auto;
    margin-top:3vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.error-message{
    font-family: Inter;
    font-size:14px;
    color:rgba(255, 0, 0, 0.601);
    font-weight: 400;
    margin-left:7.75vw;
    margin-top:-16px;
}
.input-1,
.input-1:focus,
.input-1::selection{
    width:20vw;
    margin-left:7vw;
    height:47px;
    border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
.input-1::placeholder{
    color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
.input-error,
.input-error:focus,
.input-error::selection{
    width:20vw;
    margin-left:7vw;
    height:47px;
    border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:18.52px;
outline:none;
padding-left:20px;
color: #181818;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
.input-error::placeholder{
    color: #8D8D8D;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
}
</style>