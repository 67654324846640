<template>
    <div id="container">
      <nav-bar></nav-bar>
      <div id="content">
        <div id="search-header">
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="20" viewBox="0 0 11 20" fill="none" id="back" @click="back">
              <g clip-path="url(#clip0_847_20518)">
                <path d="M11 2.85714L4.125 10L11 17.1429L9.625 20L2.38419e-07 10L9.625 -9.53673e-07L11 2.85714Z" fill="#413F3F"/>
              </g>
              <defs>
                <clipPath id="clip0_847_20518">
                  <rect width="11" height="20" fill="white" transform="matrix(-1 0 0 -1 11 20)"/>
                </clipPath>
              </defs>
            </svg>
          <h2>Pricing</h2>
          <svg xmlns="http://www.w3.org/2000/svg" width="177" height="8" viewBox="0 0 177 8" fill="none">
              <path d="M0.246559 4.01721L4.08203 7.85268L7.9175 4.01721L4.08203 0.181739L0.246559 4.01721ZM177.001 3.35289H4.08203V4.68154H177.001V3.35289Z" fill="#25AD34"/>
            </svg>
      </div>
      <div id="form">
       <div class="entry">
        <h3 class="label">Daily Subscription Cost</h3>
        <input type="text" class="input-1" :class="Error == 1?'input-error':Text==0?'input-disable':'input-active'" placeholder="Delivery Subscription Cost" v-model="subscription" ref="input-1" @keydown="moveToNextInput($event, 'input-2')" :disabled="Disabled">
        <h2 class="error-message" v-if="Error==1">{{ ErrorMessage }}</h2>
       </div>
       <div class="entry">
        <h3 class="label">Delivery Cost for the First Mile</h3>
        <input type="text" class="input-1" :class="Error == 2?'input-error':Text==0?'input-disable':'input-active'" placeholder="Delivery Cost for the First Mile" v-model="first_mile" ref="input-2" @keydown="moveToNextInput($event, 'input-3')" :disabled="Disabled">
        <h2 class="error-message" v-if="Error==2">{{ ErrorMessage }}</h2>
       </div>
       <div class="entry">
        <h3 class="label">Delivery Cost of Subsequent Half Mile</h3>
        <input type="text" class="input-1" :class="Error == 3?'input-error':Text==0?'input-disable':'input-active'" placeholder="Delivery Cost after the First mile" v-model="after_that" ref="input-3" @keydown="moveToNextInput($event, 'input-4')" :disabled="Disabled">
        <h2 class="error-message" v-if="Error==3">{{ ErrorMessage }}</h2>
       </div>
       <div class="entry">
        <h3 class="label">Percentage of Commission from Driver</h3>
        <input type="text" class="input-1" :class="Error == 4?'input-error':Text==0?'input-disable':'input-active'" placeholder="Eat Express Commission" v-model="commission" ref="input-4" @keydown="moveToNextInput($event, 'input-5')" :disabled="Disabled">
        <h2 class="error-message" v-if="Error==4">{{ ErrorMessage }}</h2>
       </div>
      </div>
      <div id="loader" v-if="Loading">
        <loader-comp></loader-comp>
      </div>
      <div class="button" @click="action">{{ BtnText }}</div>
      </div>
      <div v-if="Message>0" id="msg-encase">
        <failed-comp v-if="Message==1" msg="Failed to change metrics"></failed-comp>
      <server-crash v-if="Message==2" msg="Oops something went wrong"></server-crash>
      <success-comp v-if="Message==3" msg="Metrics changed successfully"></success-comp>
      </div>
    </div>
</template>

<script>
import NavBar from '../../components/general/nav-bar.vue'
import SuccessComp from '../../components/general/messages/success-message.vue'
import FailedComp from '../../components/general/messages/error-message.vue'
import ServerCrash from '../../components/general/messages/server-crash.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
export default {
  components:{NavBar,FailedComp,ServerCrash,SuccessComp,LoaderComp},
  data(){
    return{
      subscription:'',
      first_mile:'',
      after_that:'',
      commission:'',
      curr_subscription:'',
      curr_first_mile:'',
      curr_after_that:"",
      curr_commission:"",
      text:0,
      errorField:'',
      errorMessage:"",
      msg:0,
      disabled:true
    }
  },
  computed:{
    Text(){
      return this.text;
    },
    BtnText(){
      if(this.text==0) return 'Edit Pricing'
      else return 'Save Changes'
    },
    Error(){
                return this.errorField
            },
            ErrorMessage(){
                return this.errorMessage
            },
            Message(){
              return this.msg
            },
            Loading(){
                return this.$store.getters['user/getLoader']
            },
            Disabled(){
              return this.disabled
            }
  },
  methods:{
    back(){
      this.$router.go(-1)
    },
    async action(){
      if(this.text==0){
        this.text=1;
        this.disabled = false;
        this.subscription=this.subscription.slice(1)
        this.first_mile=this.first_mile.slice(1)
        this.after_that=this.after_that.slice(1)
        this.commission=this.commission.slice(0,-1)
      }
      else{
        let stat =await this.submit()
        if(stat == true){
          this.text=0;
          this.errorField=0;
          this.disabled = true
          this.subscription="£"+this.subscription
        this.first_mile="£"+this.first_mile
        this.after_that="£"+this.after_that
        this.commission=this.commission+"%"
        }
      }
    },
    async submit(){
      const check1 = this.numericCheck(this.subscription)
      const check2 = this.numericCheck(this.first_mile)
      const check3 = this.numericCheck(this.after_that)
      const check4 = this.numericCheck(this.commission)
         if(this.subscription==''){
          this.errorMessage="This field is required"
          this.errorField=1
          return false
         }
         else if(!check1){
          this.errorMessage="Please enter a numeric value"
          this.errorField=1
          return false
         }
         else if (this.first_mile==''){
          this.errorMessage="This field is required"
          this.errorField=2
          return false
         }
         else if(!check2){
          this.errorMessage="Please enter a numeric value"
          this.errorField=2
          return false
         }
         else if(this.after_that==''){
          this.errorMessage="This field is required"
          this.errorField=3
          return false
         }
         else if(!check3){
          this.errorMessage="Please enter a numeric value"
          this.errorField=3
          return false
         }
         else if(this.commission==''){
          this.errorMessage="This field is required"
          this.errorField=4
          return false
         }
         else if(!check4){
          this.errorMessage="Please enter a numeric value"
          this.errorField=1
          return false
         }
         else{
          this.$store.commit('user/setLoader',{value:true})
          let res = await this.$store.dispatch('admin/changeMetrics',{
            subscription_cost:parseFloat(this.subscription),
          firstMile:parseFloat(this.first_mile),
        subsequentMiles:parseFloat(this.after_that),
        commission:parseFloat(this.commission),
          })
          this.$store.commit('user/setLoader',{value:false})
          if(res==200){
            this.msg=3;
          setTimeout(()=>{
            this.msg=0;
          },1500)
          return true
          }
          else if(res==500){
            this.msg=2;
          setTimeout(()=>{
            this.msg=0;
          },1500)
          return true
          }
          else {
            this.msg=1;
            setTimeout(()=>{
            this.msg=0;
          },1500)
          return true
          }
         }
    },
    numericCheck(value){
      return /^-?\d*\.?\d+$/.test(value);
    },
    async moveToNextInput(event, nextInputName) {
      if (event.key === 'Enter') {
        if(nextInputName == 'input-5'){
          await this.submit()
        }
        else{
          this.$refs[nextInputName].focus();
        }
      }
              }
  },
  async mounted(){
    this.$store.commit('user/changeMenu',{value:6})
    let res = await this.$store.dispatch('admin/getCurrMetrics')
    if(res.status==200){
      this.subscription = "£"+res.data.subscription_cost
      this.curr_subscription=res.data.subscription_cost
      this.first_mile="£"+res.data.first_mile
      this.curr_first_mile=res.data.first_mile
      this.after_that="£"+res.data.subsequent_half_mile
      this.curr_after_that=res.data.subsequent_half_mile
      this.commission=res.data.commission+"%"
    }
    else{
      alert('Something went wrong')
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#container{
    width:100vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y:auto;
    display: grid;
    grid-template-columns: 15vw 85vw;
}
#container::-webkit-scrollbar{
    display: none;
}
#content{
  width:85vw;
  height:100vh;
  overflow-x:hidden;
  overflow-y: auto;
  background:white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left:-1vw;
}
#content::-webkit-scrollbar{
  display: none;
}
#search-header{
  display: flex;
  padding-left:1vw;
  align-items: center;
}
#back{
  margin-right:2vw;
  cursor: pointer;
}
#search-header h2{
  color: #333;
font-family: Inter;
font-size: 24.974px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.375px;
}
#search-header svg{
  margin-left:4px;
}
#form{
  padding-top:3vh;
  padding-left:2vw;
  display: grid;
  grid-template-rows: repeat(4,auto);
}
.input-1,
    .input-1:focus,
    .input-1::selection{
        width:20vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
margin-bottom:21.52px;
border:none;
outline:none;
padding-left:18px;
    }
  .input-disable{
    color: #8D8D8D;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.29px;
  }
  .input-active{
    color: #181818;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
  }
    .input-1::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error,
    .input-error:focus,
    .input-error::selection{
        width:20vw;
        height:47px;
        border-radius: 30px;
background: #FFF;
border: 1px solid rgba(255, 0, 0, 0.601);
margin-bottom:21.52px;
outline:none;
padding-left:18px;
color: #181818;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .input-error::placeholder{
        color: #8D8D8D;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
    }
    .button{
    margin-left:4.5vw;
    width:15vw;
    height:45px;
    border-radius: 48px;
background: var(--brand-color, #25AD34);
margin-top:2.5vh;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  .label{
    color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.247px;
margin-top:0px;
margin-bottom:15px;
margin-left:7px;
  }
  .entry{
    margin-top:1vh;
    margin-bottom:1vh;
  }
  .button{
    margin-left:4.5vw;
    width:15vw;
    height:45px;
    border-radius: 48px;
background: var(--brand-color, #25AD34);
margin-top:2.5vh;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
color: #FFF;
text-align: center;
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: normal;
  }
  #msg-encase{
    width:fill;
    height: fill;
    margin-top:-70vh;
    margin-left:42vw;
  }
  .error-message{
    font-family: Inter;
    font-size:14px;
    color:rgba(255, 0, 0, 0.601);
    font-weight: 400;
    margin-left:1vw;
    margin-top:-16px;
}
#loader{
  width:auto;
  display: flex;
  justify-content: center;
}
</style>