<template>
    <div id="driver-details">
        <div id="profile">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="20" viewBox="0 0 11 20" fill="none" @click="back">
                <g clip-path="url(#clip0_847_20516)">
                  <path d="M11 2.85714L4.125 10L11 17.1429L9.625 20L2.38419e-07 10L9.625 -9.53673e-07L11 2.85714Z" fill="#413F3F"/>
                </g>
                <defs>
                  <clipPath id="clip0_847_20516">
                    <rect width="11" height="20" fill="white" transform="matrix(-1 0 0 -1 11 20)"/>
                  </clipPath>
                </defs>
              </svg>
            <h2>Profile</h2>
        </div>
        <img :src="Image" :alt="value.name" id="profile-image" @error="handleImageError" v-if="Image" @click="toggle">
        <img src="https://firebasestorage.googleapis.com/v0/b/eat-express-32d51.appspot.com/o/ASSETS%2FPROFILE-1.png?alt=media&token=4f4850d0-35ed-4abe-a33d-4ce25a074dae" :alt="value.name" id="profile-image" v-else>
        <h2 id="profile-name">{{ value.name }}</h2>
        <div id="general-info">
            <div class="entry">
                <h2 class="key">Contact Number: </h2>
                <h2 class="value">{{ value.phone}}</h2>
            </div>
            <div class="entry">
                <h2 class="key">Email ID: </h2>
                <h2 class="value">{{ value.email}}</h2>
            </div>
            <!-- <div class="entry">
                <h2 class="key">Rating: </h2>
                <h2 class="value">{{ value.rating}}</h2>
            </div> -->
            <div class="entry">
                <h2 class="key">No. of Deliveries: </h2>
                <h2 class="value" v-if="orders">{{ orders}}</h2>
                <h2 v-else class="value">0</h2>
            </div>
            <div class="entry">
                <h2 class="key">DOB:</h2>
                <h2 class="value" v-if="value.dob">{{ value.dob.split('T')[0] }}</h2>
                <h2 class="value" v-else>----</h2>
            </div>
            <div class="entry">
                <h2 class="key">Vehicle Type: </h2>
                <h2 class="value">{{ vehicle.vehicle_type }}</h2>
                <!-- <h2 class="value">{{ value.vehicle}}</h2> -->
            </div>
            <div class="entry">
                <h2 class="key">Registration Number:</h2>
                <h2 class="value">{{ vehicle.registrationNumber }}</h2>
            </div>
            <div class="entry">
                <h2 class="key">Vehicle Details:</h2>
                <h2 class="value">{{ vehicle.vehicle_details }}</h2>
            </div>
            <div class="entry">
                <h2 class="key">Address: </h2>
                <h2 class="value">{{ value.address}}</h2>
            </div>
        </div>
        <div id="mid-btn" @click="weakly">Weekly Earnings</div>
        <div id="image-expanded" v-if="Expanded">
            <img :src="Image" :alt="value.name" @error='handleImageError' id='profile-image-expanded' v-if="Image" @click="toggle">
        <img src="https://firebasestorage.googleapis.com/v0/b/eat-express-32d51.appspot.com/o/ASSETS%2FPROFILE-1.png?alt=media&token=4f4850d0-35ed-4abe-a33d-4ce25a074dae" :alt="value.name" id='profile-image-expanded' v-else @click="toggle">
        
        </div>
        <!-- <h2 id="legal">Driver Legal Details</h2>
        <div id="legal-info">
            <div class="entry">
                <h2 class="key">National Insurance Certificate: </h2>
                <a :href="value.driver_national_insurance" class="driver-link">certificate.pdf</a> 
            </div>
            <div class="entry">
                <h2 class="key">Drivers Insurance Certificate: </h2>
                <a :href="value.vehicle_insurance" class="driver-link">certificate.pdf</a>
            </div>
            <div class="entry">
                <h2 class="key">Drivers license: </h2>
                <a :href="value.driver_license" class="driver-link">license.pdf</a> 
            </div>
        </div> -->
    </div>
</template>

<script>

export default {
    data(){
        return{
            expanded:false
        }
    },
    props:['value','orders','vehicle'],
    computed:{
    Image(){
        return this.value.driver_image
    },
    Expanded(){
        return this.expanded;
    },
},
    methods:{
        back(){
            this.$router.go(-1)
        },
        weakly(){
            this.$router.push({name:'driverWeakly',params:{id:this.$route.params.id}})
        },
        handleImageError() {
      this.image = "https://firebasestorage.googleapis.com/v0/b/eat-express-32d51.appspot.com/o/ASSETS%2FPROFILE-1.png?alt=media&token=4f4850d0-35ed-4abe-a33d-4ce25a074dae"
    },
    toggle(){
        this.expanded = !this.expanded
    }
    },
   
}
</script>

<style scoped>
#driver-details{
    padding-bottom:4vh;
    overflow-x:hidden;
    overflow-y:auto;
}
#driver-details::-webkit-scrollbar{
    display: none;
}
#profile{
    display: flex;
    align-items: center;
    margin-left:1vw;
    margin-top:2vh;
}
#profile svg{
    margin-right:2vw;
    cursor: pointer;
}
#profile h2{
    color: #413F3F;
font-family: Montserrat;
font-size: 22.093px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.375px;
}
#profile-image{
    width:100px;
    height:100px;
    border-radius: 100px;
    margin-left:1.5vw;
    margin-top:1vh;
    margin-bottom:0px;
}
#profile-name{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.307px;
margin-top:4px;
margin-left:1.5vw;
}
#legal{
    color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.307px;
    margin-top:6vh;
    margin-left:1.5vw;

}
#general-info,
#legal-info{
    padding-left:1.5vw;
    margin-top:2.5vh
}
.entry{
    display: grid;
    grid-template-columns: 12vw 16vw;
    margin-top:15px;
    margin-bottom:15px;
}
.key{
    color: #413F3F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.305px;
    margin-top:0px;
    margin-bottom:0px;
}
.value{
    float:right;
    margin-right:5vw;
    color: #413F3F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.305px;
    text-align: start;
    margin-top:0px;
    margin-bottom:0px;
}
.detail{
    margin-top:15px;
    margin-bottom:15px;
    color: #1c1c1c;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.307px;
}
.detail-value{
    color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.307px;
margin-left:1vw;
}
.driver-link{
    color: #25AD34;
font-family: Inter;
font-size: 18px;
font-style: italic;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
text-decoration-line: underline;
margin-left:1vw;
cursor: pointer;
}
#mid-btn{
    height:51px;
    border-radius: 17px;
    width:20vw;
    margin-left:7.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #25AD34;
    cursor:pointer;
    margin-top:3.5vh;
    color: #FFF;
text-align: center;
font-family: Inter;
font-size: 17.506px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
#image-expanded{
    position:absolute;
    z-index:1;
    margin-top:-40vh;
    width:34vw;
    display:flex;
    justify-content:center;
    animation: zoomOut 1s ease-in-out;
}
#profile-image-expanded{
    width:350px;
    height:350px;
    border-radius:400px;
    cursor:pointer;
}
@keyframes zoomOut {
    from {
      transform: scale(0); /* Initial scale */
    }
    to {
      transform: scale(1); /* Final scale */
    }
  }
</style>