<template>
    <div>
        <div id="container">
            <nav-bar></nav-bar>
            <div id="loader" v-if="Loading">
              <loader-comp></loader-comp>
            </div>
            <div id="content" v-if="Message==0">
              <manage-driver :class="Reject?'shadow':''" :value="Value"></manage-driver>
              <reject-driver v-if="Reject" :value="Value"></reject-driver>
            </div>
            <div id="error" v-else>
              <error-message v-if="Message==1" msg="No driver found"></error-message>
              <server-crash v-if="Message==2" msg="Oops something went wrong"></server-crash>
            </div>
        </div>
    </div>
</template>

<script>
import NavBar from '../../components/general/nav-bar.vue'
import ManageDriver from '@/components/driver/manage-driver.vue';
import rejectDriver from '@/components/driver/reject-driver.vue';
import ErrorMessage from '../../components/general/messages/error-message.vue'
import ServerCrash from '../../components/general/messages/server-crash.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
export default {
  data(){
    return{
      value:"",
      driver:true,
      msg:0
    }
  },
    components:{NavBar,ManageDriver,rejectDriver,ErrorMessage,ServerCrash,LoaderComp},
    computed:{
      Reject(){
        return this.$store.getters['driver/getReject']
      },
      Value(){
        return this.value;
      },
      Driver(){
        return this.driver
      },
      Message(){
      return this.msg
    },
    Loading(){
                return this.$store.getters['user/getLoader']
            }
    },
    async mounted(){
        this.$store.commit('user/changeMenu',{value:2})
        this.$store.commit('driver/setRejected',{value:false})
        this.$store.commit('user/setLoader',{value:true})
        let res= await this.$store.dispatch('driver/driverReqProfile',{id:this.$route.params.id})
        this.$store.commit('user/setLoader',{value:false})
        if(res.status==200){
          this.value=res.data
        }
        else if(res.status==500){
          this.driver=false
          this.msg=2;
        }
        else{
          this.driver=false
          this.msg=1
        }
    }
}
</script>

<style scoped>
.shadow{
    background: #D9D9D9;
  }
  #container{
    width:100vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y:auto;
    display: grid;
    grid-template-columns: 15vw 85vw;
  }
  #container::-webkit-scrollbar{
    display: none;
  }
  #content{
    width:85vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y: auto;
    background:white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-left:-1vw;
    display: grid;
    grid-template-columns: 50vw 40vw;
    }
    #content::-webkit-scrollbar{
    display: none;
    }
    #loader{
      width:85vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y: auto;
    background:white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-left:-1vw;
    display: flex;
    justify-content:center;
    align-items: center;;
    }
    #error{
      width:85vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y: auto;
    background:white;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-left:-1vw;
    display:flex;
    align-items:center;
    justify-content: center;
    }
</style>