<template>
    <div id="reject-driver">
        <div id="reject-header">
            <h2>Reject account</h2>
            <div id="close-button" @click="back">X</div>
        </div>
        <div id="reject-details">
            <img src="https://firebasestorage.googleapis.com/v0/b/cappy-notes.appspot.com/o/default-profile.png?alt=media&token=d541afc8-9418-4e98-b472-eae4d9748786" :alt="name">
            <div id="details-text">
               <h2>{{ value.name }}</h2>
               <h3>{{ value.phone }}</h3>
            </div>
           </div>
        <div id="options">
            <!-- <div class="option">
                <input type="radio" id="1" value="1" @click="select(1)" v-model="selectedOption">
                <h3 class="option-text">The insurance details  <span class="reason">you have  provided are unsatisfactory for the purpose of EatExpress service</span></h3>
            </div> -->
            <!-- <div class="option">
                <input type="radio" id="1" value="2" @click="select(2)" v-model="selectedOption">
                <h3 class="option-text">Other<span class="reason"></span></h3>
            </div> -->
            <input type="text" :class="Error?'input-error':'input-1'" placeholder="Rejection Reason" v-model="reason">
            <h2 class="error-message" v-if="Error">This field is required</h2>
        </div>
        <div class="reject-btn-1" @click="submit">Reject Account</div>
        <div class="loader" v-if="Loading">
            <loader-comp></loader-comp>
        </div>
    </div>
</template>

<script>
import LoaderComp from '../general/loader-comp.vue'
export default {
    components:{LoaderComp},
    data(){
        return{
            lock:false,
            selectedOption:'',
            reason:'',
            text:false,
            error:false,
            loading:false
        }
    },
    computed:{
        Lock(){
            return this.lock;
        },
        Text(){
            return this.text
        },
        Error(){
            return this.error
        },
        Loading(){
                return this.loading
            }
    },
    methods:{
        back(){
            this.$store.commit('driver/setRejected',{value:false})
        },
        select(val){
            this.lock = true
           if(val==2){this.text=true}
           else
           this.text=false
            
        },
       async submit(){
        if(this.reason==''){
                    this.error='true'
                    return
                }
                else{
                    this.loading=true
                    let status = await this.$store.dispatch('driver/rejectDriver',{id:this.value._id,reason:this.reason})
                    this.loading=false;
                    if(status==200){
                        this.$router.push({name:"manageDrivers"})
                    }
                    else if(status==500){
                        alert('Something went wrong at our end')
                    }
                    else{
                        alert("Failed to verify driver")
                    }
                }
        }
    },
    props:['value']
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#reject-driver{
    padding-top:3vh;
}
#reject-header{
    padding-left:3vw;
    display: flex;
    align-items: center;
}
#reject-header h2{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
#close-button{
    width: 30px;
height: 30px;
flex-shrink: 0;
border-radius: 4px;
background: #D9D9D9;
display: flex;
align-items: center;
justify-content: center;
color: #000;
text-align: center;

/* Sub text */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
cursor: pointer;
margin-left:18vw;
}
#reject-details{
    width:30vw;
    display: grid;
    display: flex;
    margin-left:2vw;
    padding-left:1vw;
    margin-top:4.5vh;
    padding-top:0.75vh;
    padding-bottom:0.75vh;
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.10);
}
#reject-details img{
    width:80px;
    height:80px;
    border-radius: 80px;
    border:1px solid black
}
#details-text{
    margin-left:20px;
    margin-top:-4px;
}
#details-text h2{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom:0px;
}
#details-text h3{
    color: #413F3F;
font-family: Inter;
font-size: 15.548px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.27px;
margin-top:16px;
}
#options{
    margin-top:4vh;
}
.option{
    width:30vw;
    padding-left:2vw;
    padding-right:2vw;
    display: flex;
margin-top: top 1.25vh;
margin-bottom:1.25vh;
    align-items: center;
}
.option-text{
    color: #413F3F;
text-align: justify;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.2px;
margin-left:2vw;
}
.reason{
    color: #413F3F;
text-align: justify;
font-family: Inter;
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
}
.reject-btn-1{
    border-radius: 8px;
background: var(--brand-color, #25AD34);
    width:20vw;
    margin-left:8vw;
    height:45px;
    margin-top:5vh;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.reject-btn-2{
    border-radius: 8px;
    background: var(--Brand-color-3, #8d8d8d9f);
    width:20vw;
    margin-left:8vw;
    height:45px;
    margin-top:5vh;
    cursor:pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.input-1,
.input-1:focus,
.input-1:active{
    outline: none;
    border:none;
    border-radius: 16px;
background: #FFF;
box-shadow: 0px 1.40264px 2.80527px 0px rgba(0, 0, 0, 0.15);
border:none;
outline: none;
height:31px;
width:24vw;
margin-left:5vw;
padding-left:0.75vw;
color: #1f1f1f;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.281px;   
}
.input-1::placeholder{
    color: #8D8D8D;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.281px;   
}
.input-error,
.input-error:focus,
.input-error:active{
    outline: none;
    border:none;
    border-radius: 16px;
background: #FFF;
border:1px solid rgba(255, 0, 0, 0.601);
outline:none;
height:31px;
width:24vw;
margin-left:5vw;
padding-left:0.75vw;
color: #1f1f1f;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.281px;   
}
.input-error::placeholder{
    color: #8D8D8D;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.281px;   
}
.error-message{
    font-family: Inter;
    font-size:14px;
    color:rgba(255, 0, 0, 0.601);
    font-weight: 400;
    margin-left:5.75vw;
}
#loader{
    width:auto;
    margin-top:3.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>