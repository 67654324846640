import LandingPage from "../views/dashboard/landing-page.vue";
const routes = [
  {
    name: "home",
    path: "/home",
    component: LandingPage,
  },
  {
    name: "index",
    path: "/",
    component: LandingPage,
  },
];

export default routes;
