<template>
   <div>
    <div id="container">
      <nav-bar></nav-bar>
      <div id="loader" v-if="Loading">
        <loading-comp></loading-comp>
      </div>
      <div id="content" v-if="Message==0">
          <search-comp :id="outletId"></search-comp>
          <!-- <div id="order-buttons">
            <left-button></left-button>
            <right-button></right-button>
         </div> -->
         <div id="order-table">
          <div id="table-heading">
            <div class="heading">Order ID</div>
              <div class="heading" style="margin-left:0.75vw">Status</div>
              <div class="heading">Customer</div>
              <div class="heading">Food Outlet</div>
              <div class="heading">Driver</div>
              <div class="heading">Delivery Charge</div>
              <div class="heading">EE Share</div>
              <div class="heading">Payment Mode</div>
              <div class="heading" style="margin-left:5vw;">Action</div>
        </div>
        <div class="table-divider"></div>
        <div id="table-content" v-for="order,index in Orders" :key="index">
          <div class="table-row">
            <div class="entry" style="width:7.5vw">{{order.orderId}}</div>
              <div class="entry" style="margin-left:0.75vw;">{{order.status}}</div>
              <div class="entry">{{ order.customer_name }}</div>
              <div class="entry" style="width:9.5vw">{{order.outletDetails.name}}</div>
              <div class="entry" style="width:9.5vw">{{order.driver.name}}</div>
              <div class="entry">£{{order.delivery_price}}</div>
              <div class="entry">£{{order.commission_amount}}</div>
              <div class="entry">{{order.mode_of_payment}}</div>
              <div class="table-btn" @click="redirect(order.orderId)">
               Payment Details
            </div>
          </div>
          <div class="table-divider"></div>
        </div>
         </div>
      </div>
      <div id="message-comp" v-if="Message==1">
        <server-crash msg="Something went wrong at our end"></server-crash>
      </div>
      <div id="message-comp" v-if="Message==2">
        <error-comp msg="Failed to fetch order details"></error-comp>
      </div>
      <div id="message-comp" v-if="Message==3">
        <empty-list msg="No orders placed yet"></empty-list>
      </div>
  </div>
   </div>
</template>


<script>
import NavBar from '../../components/general/nav-bar.vue'
import SearchComp from '../../components/driver/order-search.vue'
// import LeftButton from '../../components/general/left-button.vue'
// import RightButton from '../../components/general/right-button.vue'
import LoadingComp from '../../components/general/loader-comp.vue'
import ServerCrash from '../../components/general/messages/server-crash.vue'
import ErrorComp from '../../components/general/messages/error-message.vue'
import EmptyList from '../../components/general/messages/no-items.vue'
export default {
    components:{NavBar,SearchComp,LoadingComp,ServerCrash,ErrorComp,EmptyList},
    data(){
      return{
        orders:[],
        outletId:'',
        message:0
      }
    },
    computed:{
      Orders(){
        return this.orders;
      },
      Loading(){
        return this.$store.getters['user/getLoader']
      },
      Message(){
        return this.message;
      }
    },
    methods:{
      redirect(value){
        this.$router.push({name:'driverPayment',params:{id:value}})
      }
    },
    async mounted(){
      this.$store.commit('user/changeMenu',{value:2})
      this.$store.commit('user/setLoader',{value:true})
      let res = await this.$store.dispatch('driver/driverProfile',{id:this.$route.params.id})
      if(res.status==200){
        this.orders = res.orders
        if(this.orders.length==0){
          this.message=3;
        }
      }
      else if(res.status==500){
        this.msg=1;
      }
      else{
        this.msg=2;
      }
      this.$store.commit('user/setLoader',{value:false})
    }
    
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#container{
    width:100vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y:auto;
    display: grid;
    grid-template-columns: 15vw 85vw;
  }
  #container::-webkit-scrollbar{
    display: none;
  }
  #content{
  width:85vw;
  height:100vh;
  overflow-x:hidden;
  overflow-y: auto;
  background:white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left:-1vw;
  }
  #content::-webkit-scrollbar{
  display: none;
  }
  #loader,
.message-comp{
  width:85vw;
height:100vh;
overflow-x:hidden;
overflow-y: auto;
background:white;
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
margin-left:-1vw;
display:flex;
align-items:center;
justify-content: center;
}
  #order-buttons{
    float: right;
    margin-right:4vw;
    display: flex;
    margin-top:5vh;
  }
  #order-table{
    border-radius: 18px;
    border: 1.094px solid var(--Brand-color-3, #8D8D8D);
    background: #FFF;
    box-shadow: 0px 1.09416px 2.18831px 0px rgba(0, 0, 0, 0.10);
    width:78vw;
    margin-left:2vw;
    height:61vh;
    margin-top:13vh;
    overflow:auto;
    padding-top:1.5vh;
    padding-bottom:2vh;
    padding-left:1.75vw;
    padding-right:0.75vw;
  }
  #order-table::-webkit-scrollbar{
    display: none;
  }
  #table-heading{
    display: grid;
    grid-template-columns: 8vw 8vw 10vw 10vw 10vw 6vw 6vw 8vw 8vw;
    margin-bottom:2vh;
  }
  .heading{
    color: #413F3F;
  font-family: Inter;
  font-size: 15.318px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.219px;
  }
  .table-divider{
    background:#8d8d8d45;
    height:1px;
    width:75vw;
    margin-left:0.25vw;
    margin-top:1vh;
    margin-bottom:1vh;
  }
  .table-row{
    display:grid;
    grid-template-columns: 8vw 8vw 10vw 10vw 10vw 6vw 6vw 8vw 8vw;   
  }
  .entry{
    color: #413F3F;
  font-family: Inter;
  font-size: 15.318px;
  font-style: normal;
  font-weight: 500;
  line-height: 41px;
  letter-spacing: 0.219px;
  word-wrap: break-word;
  }
  .table-btn{
    border-radius: 10px;
  background: #25AD34;
  width:8vw;
  height:41px;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 15.506px;
  font-style: normal;
  font-weight: 400;
  line-height: 41px;
  cursor: pointer;
  margin-left:4vw;
  }
</style>