const hostName = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import Cookies from "js-cookie";
export default {
  async driverRequests() {
    try {
      let reqUrl = `${hostName}/admin/onboarding-new-drivers-list`;
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return { status: res.status, data: res.data.data };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async driverReqProfile(context, payload) {
    try {
      let reqUrl = `${hostName}/admin/onboarding-new-drivers-details/${payload.id}`;
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return { status: 200, data: res.data.data };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async acceptDriver(context, payload) {
    try {
      let reqBody = {
        driverID: payload.id,
      };
      let reqUrl = `${hostName}/admin/accept-onboarding-driver`;
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.post(reqUrl, reqBody, { headers: reqHeaders });
      return res.status;
    } catch (err) {
      return err.response.status;
    }
  },
  async rejectDriver(context, payload) {
    try {
      let reqBody = {
        driverID: payload.id,
        reason: payload.reason,
      };
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let reqUrl = `${hostName}/admin/reject-onboarding-driver`;
      let res = await axios.post(reqUrl, reqBody, { headers: reqHeaders });
      return res.status;
    } catch (err) {
      return err.response.status;
    }
  },
  async driverList() {
    try {
      let reqUrl = `${hostName}/admin/drivers-list`;
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return {
          status: 200,
          data: res.data.data,
          activeFoodOutetsCount: res.data.activeFoodOutetsCount,
          totalFoodOutetsCount: res.data.totalFoodOutetsCount,
          activeDriversCount: res.data.activeDriversCount,
          totalDriversCount: res.data.totalDriversCount,
        };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async driverProfile(context, payload) {
    try {
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let reqUrl = `${hostName}//admin/driver-detail/${payload.id}`;
      let res = await axios.get(reqUrl, { headers: reqHeaders });
      if (res.status == 200) {
        return {
          status: 200,
          data: res.data.data,
          orders: res.data.ordersList,
          vehicle: res.data.vehicle,
        };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async suspendDriver(context, payload) {
    try {
      let reqUrl = `${hostName}/admin/suspend-driver-toggle`;
      let reqBody = {
        driverID: payload.value,
      };
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.post(reqUrl, reqBody, { headers: reqHeaders });
      return { status: res.status };
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async deleteDriver(context, payload) {
    try {
      let reqUrl = `${hostName}/admin/driver`;
      let reqBody = {
        driverID: payload.id.trim(),
        password: payload.password,
      };
      let reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      let res = await axios.delete(reqUrl, {
        headers: reqHeaders,
        data: reqBody,
      });
      return res.status;
    } catch (err) {
      return err.response.status;
    }
  },
  async searchDriver(context, payload) {
    try {
      let searchVal = payload.value.toString().trim();
      let drivers = context.getters["getDrivers"];
      let coordinates = context.getters["getCoordinates"];
      let val = coordinates[drivers[searchVal]];
      return val;
    } catch (err) {
      return "";
    }
  },
  async weekData(context, payload) {
    try {
      const reqUrl = `${hostName}/admin/payments-driver`;
      const reqHeaders = { Authorization: "Bearer " + Cookies.get("token") };
      const reqBody = { date: payload.date, driverId: payload.driver };
      let res = await axios.post(reqUrl, reqBody, { headers: reqHeaders });
      if (res.status == 200)
        return { status: 200, data: res.data.driverPayments };
    } catch (err) {
      return { status: err.response.status };
    }
  },
};
