import OutletsPage from "../views/outlet/outlets-page.vue";
import OutletPage from "../views/outlet/details-page.vue";
import OrderHistory from "../views/outlet/order-history.vue";
import PaymentDetail from "../views/outlet/payment-details.vue";
import WeaklyPayments from "../views/outlet/weakly-payments.vue";
const routes = [
  {
    name: "outlets",
    path: "/outlets",
    component: OutletsPage,
  },
  {
    name: "outlet",
    path: "/outlet/:id",
    component: OutletPage,
    props: true,
  },
  {
    name: "orderHistory",
    path: "/order-history/:id",
    component: OrderHistory,
    props: true,
  },
  {
    name: "paymentDetail",
    path: "/payment-detail/:id",
    component: PaymentDetail,
    props: true,
  },
  {
    name: "OutletWeakly",
    path: "/outlet/weekly/:id",
    component: WeaklyPayments,
    props: true,
  },
];

export default routes;
