import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";
export default {
  namespaced: true,
  state() {
    return {
      suspended: false,
      comp: "0", // 0 for suspend ,1 for delete
      rejected: false,
      drivers: {},
      center: {},
    };
  },
  mutations,
  getters,
  actions,
};
