const hostName = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import router from "../../router/router";
import Cookies from "js-cookie";
export default {
  async signIn(context, payload) {
    try {
      const reqBody = {
        email: payload.email,
        password: payload.password,
      };
      const reqUrl = `${hostName}/admin/signin`;
      let res = await axios.post(reqUrl, reqBody);
      if (res.status == 200) {
        Cookies.set("email", res.data.payload.email, { expires: 30 });
        Cookies.set("id", res.data.payload.id, { expires: 30 });
        Cookies.set("name", res.data.payload.name, { expires: 30 });
        Cookies.set("userType", res.data.payload.userType, { expires: 30 });
        Cookies.set("authenticated", true, { expires: 30 });
        Cookies.set("token", res.data.token, { expires: 30 });
        router.push({ name: "home" });
        return { status: 0 };
      }
    } catch (err) {
      return { status: err.response.status };
    }
  },
  async forgotPassword1(context, payload) {
    try {
      const reqBody = {
        email: payload.email,
      };
      const reqUrl = `${hostName}/admin/generate-new-otp`;
      let res = await axios.post(reqUrl, reqBody);
      return res.status;
    } catch (err) {
      return 404;
    }
  },
  async forgotPassword2(context, payload) {
    try {
      const reqBody = {
        email: Cookies.get("password-email"),
        newPassword: payload.password,
        confirmPassword: payload.confirm,
        otp: payload.otp,
      };
      const reqUrl = `${hostName}/admin/forgot-password`;
      let res = await axios.post(reqUrl, reqBody);
      return res.status;
    } catch (err) {
      return 404;
    }
  },
};
