<template>
  <div>
      <div id="container">
          <nav-bar></nav-bar>
          <div id="loader" v-if="Loading">
            <loader-comp></loader-comp>
          </div>
          <div id="content" v-if="Message==0">
            <div id="search-header">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="20" viewBox="0 0 11 20" fill="none" id="back" @click="back">
                  <g clip-path="url(#clip0_847_20518)">
                    <path d="M11 2.85714L4.125 10L11 17.1429L9.625 20L2.38419e-07 10L9.625 -9.53673e-07L11 2.85714Z" fill="#413F3F"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_847_20518">
                      <rect width="11" height="20" fill="white" transform="matrix(-1 0 0 -1 11 20)"/>
                    </clipPath>
                  </defs>
                </svg>
              <h2>Payment Details</h2>
              <svg xmlns="http://www.w3.org/2000/svg" width="177" height="8" viewBox="0 0 177 8" fill="none">
                  <path d="M0.246559 4.01721L4.08203 7.85268L7.9175 4.01721L4.08203 0.181739L0.246559 4.01721ZM177.001 3.35289H4.08203V4.68154H177.001V3.35289Z" fill="#25AD34"/>
                </svg>
          </div>
          <div id="details">
            <div class="entry">
              <h2 class="key">Order ID: </h2>
              <h2 class="value">{{ Details.orderId }}</h2>
            </div>
            <div class="entry">
              <h2 class="key">Eat Express Share:</h2>
              <h2 class="value">£ {{ Details.commission_amount}}</h2>
            </div>
            <div class="entry">
              <h2 class="key">Driver Share:</h2>
              <h2 class="value">£ {{ Details.driver_income }}</h2>
            </div>
            <div class="entry">
              <h2 class="key">Food Outlet:</h2>
              <h2 class="value">{{ Outlet }}</h2>
            </div>
            <div class="entry">
              <h2 class="key">Driver Name:</h2>
              <h2 class="value">{{ Rider }}</h2>
            </div>
            <div class="entry">
              <h2 class="key">Payment Method:</h2>
              <h2 class="value">{{ Details.mode_of_payment }}</h2>
            </div>
            <div class="entry">
              <h2 class="key">Order Value:</h2>
              <h2 class="value">£ {{ Details.food_order_value }}</h2>
            </div>
            <div class="entry">
              <h2 class="value">Date and Time:</h2>
              <h2 class="key">{{ Time }}</h2>
            </div>
            <!-- <div class="entry">
              <h2 class="key">Order Items:</h2>
              <h2 class="value">{{ Details.delivery_items }}</h2>
            </div> -->
            <!-- <div class="entry">
              <h2 class="key">Pay Transaction ID:</h2>
              <h2 class="value">{{ details.pid }}</h2>
            </div> -->
            <div class="entry">
              <h2 class="key">Customer Name:</h2>
              <h2 class="value">{{Details.customer_name}}</h2>
            </div>
            <!-- <div class="entry">
              <h2 class="key">Outlet</h2>
              <h2 class="value">{{Outlet}}</h2>
            </div> -->
            <!-- <div class="entry">
              <h2 class="key">Transaction ID:</h2>
              <h2 class="value">{{ details.transactionId }}</h2>
            </div> -->
            </div>
      </div>
      <div class="message-comp" v-if="Message==1">
        <empty-list msg="No order found"></empty-list>
      </div>
      <div class="message-comp" v-if="Message==2">
        <error-comp msg="Failed to fetch order details"></error-comp>
      </div>
      <div class="message-comp" v-if="Message==3">
        <server-crash msg="Something went wrong at our end"></server-crash>
      </div>

  </div>
  </div>
</template>

<script>
import NavBar from '../../components/general/nav-bar.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
import ServerCrash from '../../components/general/messages/server-crash.vue'
import ErrorComp from '../../components/general/messages/error-message.vue'
import EmptyList from '../../components/general/messages/no-items.vue'
export default {
  components:{NavBar,LoaderComp,ServerCrash,ErrorComp,EmptyList},
  data(){
    return{
      details:{},
      message:0,
      outletName:'',
      riderName:'',
      time:''
    }
  },
  computed:{
    Loading(){
      return this.$store.getters['user/getLoader']
    },
    Message(){
      return this.message;
    },
    Details(){
      return this.details
    },
    Outlet(){
      return this.outletName
    },
    Rider(){
      return this.riderName
    },
    Time(){
      return this.time
    }
  },
  methods:{
    back(){
      this.$router.go(-1)
    },
    async convertTimeTo12HourFormat(time) {
    let data = time.split('T')
    let timeData = data[1].split(':')
    let date =data[0]
   let hour = parseInt(timeData[0])
   let minute = parseInt(timeData[1])
   let period = "AM";
   if (hour >= 12) {
      period = "PM"
      hour = hour-12;
   }
return `${hour}:${minute} ${period}: ${date}`;
}
  },
  
  async mounted(){
    this.$store.commit('user/setLoader',{value:true})
    let res = await this.$store.dispatch('payment/paymentDetails',{value:this.$route.params.id})
    this.$store.commit('user/setLoader',{value:false})
    if(res.status==200){
      this.details= res.data
      this.outletName = res.data.outletDetails.name
      this.riderName = res.data.driver.name || 'Not assigned'
      this.time = await this.convertTimeTo12HourFormat(res.data.createdAt)
    }
    else if(res.status==404){
      this.message=1
    }
    else if(this.status==500){
      this.message=3
    }
    else {
      this.message=2
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#container{
  width:100vw;
  height:100vh;
  overflow-x:hidden;
  overflow-y:auto;
  display: grid;
  grid-template-columns: 15vw 85vw;
}
#container::-webkit-scrollbar{
  display: none;
}
#content{
width:85vw;
height:100vh;
overflow-x:hidden;
overflow-y: auto;
background:white;
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
margin-left:-1vw;
}
#loader,
.message-comp{
  width:85vw;
height:100vh;
overflow-x:hidden;
overflow-y: auto;
background:white;
border-top-left-radius: 20px;
border-bottom-left-radius: 20px;
margin-left:-1vw;
display:flex;
align-items: center;
justify-content: center;

}
#content::-webkit-scrollbar{
display: none;
}
#search-header{
  display: flex;
  padding-left:1vw;
  align-items: center;
}
#back{
  margin-right:2vw;
  cursor: pointer;
}
#search-header h2{
  color: #333;
font-family: Inter;
font-size: 24.974px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.375px;
}
#search-header svg{
  margin-left:4px;
}
#details{
  border-radius: 18.274px;
border: 3.046px solid var(--brand-color, #25AD34);
background: #FFF;
width:36vw;
margin-left:2vw;
height:fit-content;
padding-top:2vh;
padding-bottom:3vh;
padding-left:2vw;
margin-top:4vh;
}
.key{
  color: #413F3F;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.305px;
margin-top:0px;
margin-bottom:0px;
}
.value{
  float:right;
  margin-right:5vw;
  color: #413F3F;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.305px;
  text-align: start;
  margin-top:0px;
  margin-bottom:0px;
}
.entry{
  display: grid;
  grid-template-columns: 16vw 20vw;
  margin-top:15px;
  margin-bottom:15px;
}
</style>