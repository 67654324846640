<template>
    <div id="container">
      <nav-bar></nav-bar>
      <div id="loader" v-if="Loading">
        <loader-comp></loader-comp>
      </div>
      <div id="content" v-else>
        <div id="col1">
          <search-comp></search-comp> 
        <div id="row2">
          <h2>Outlets</h2>
          <div id="outlet-container" v-if="Show">
            <outlet-comp v-for="value,index in Outlets" :key="index" :name="value.restaurant_name" :picture="value.profile_image" :id="value._id"></outlet-comp>
          </div>
          <div id="messages" v-else>
            <empty-list v-if="Message==1" msg="No outlet found"></empty-list>
            <error-message v-if="Message==2" msg="Failed to fetch outlets"></error-message>
            <server-crash v-if="Message==3" msg="Oops something went wrong"></server-crash>
        </div>
        </div>
        </div>
        <div id="divider"></div>
        <active-order></active-order>
      </div>
    </div>
</template>

<script>
import NavBar from '../../components/general/nav-bar.vue'
import ActiveOrder from '../../components/outlet/side-map.vue'
import SearchComp from '../../components/outlet/search-comp.vue'
import OutletComp from '../../components/outlet/outlet-comp.vue'
import EmptyList from '../../components/general/messages/no-items.vue'
import ErrorMessage from '../../components/general/messages/error-message.vue'
import ServerCrash from '../../components/general/messages/server-crash.vue'
import LoaderComp from '../../components/general/loader-comp.vue'
export default {
  components:{NavBar,ActiveOrder,SearchComp,OutletComp,EmptyList,ErrorMessage,ServerCrash,LoaderComp},
  data(){
    return{
      outlets:[],
    msg:0
    }
  },
  computed:{
    Outlets(){
   return this.outlets
  },
  Show(){
    return this.outlets.length>0
  },
  Message(){
    return this.msg
  },
  Loading(){
                return this.$store.getters['user/getLoader']
            }
  },
  async mounted(){
    this.$store.commit('user/changeMenu',{value:1})
    this.$store.commit('user/setLoader',{value:true})
    let res = await this.$store.dispatch('outlet/getOutlets')
    this.$store.commit('user/setLoader',{value:false})
    if(res.status==200){
      this.outlets=res.data
      this.$store.commit('dashboard/setActiveOutlet',{value:res.activeFoodOutetsCount})
      this.$store.commit('dashboard/setTotalOutlet',{value:res.totalFoodOutetsCount})
      this.$store.commit('dashboard/setActiveRiders',{value:res.activeDriversCount})
      this.$store.commit('dashboard/setTotalRiders',{value:res.totalDriversCount})
      this.$store.commit('dashboard/setOutletCoordinates',{value:res.data})
      this.$store.commit('outlet/setOutlets',{value:res.data})
      if(this.outlets==[]) this.msg=1;
    }
    else if(res.status==500){
      this.msg=3;
    }
    else{
      this.msg=2;
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
#container{
    width:100vw;
    height:100vh;
    overflow-x:hidden;
    overflow-y:auto;
    display: grid;
    grid-template-columns: 15vw 85vw;
}
#container::-webkit-scrollbar{
    display: none;
}
#content{
  width:85vw;
  height:100vh;
  overflow-x:hidden;
  overflow-y: auto;
  background:white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left:-1vw;
  display: grid;
  grid-template-columns: 57vw 3vw 25vw;
}
#content::-webkit-scrollbar{
  display: none;
}
#loader{
  width:85vw;
  height:100vh;
  overflow-x:hidden;
  overflow-y: auto;
  background:white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-left:-1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
#col1{
  width:55vw;
}
#divider{
  width:3.5px;
  height:fill;
  background: #D9D9D9;
  margin-top:10vh;
  margin-bottom:5vh;
}
#row2 h2{
  color: #413F3F;
font-family: Inter;
font-size: 21px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.247px;
margin-left:3vw;
}
#outlet-container{
  height: auto;
  display: grid;
  grid-template-columns: repeat(2,25vw);
  gap:2vw;
  margin-top:3vh;
  padding-left:1.5vw;
  padding-bottom: 4vh;
}
#outlet-container::-webkit-scrollbar{
  display: none;
}
#msg{
  text-align: center;
  font-family: Inter;
  font-size:22px;
  font-weight: 500;
  margin-top:5vh;
  }
  #messages{
    display:flex;
    align-items: center;
    justify-content: center;
  }
</style>