export default {
  getSuspended(state) {
    return state.suspended;
  },
  getComp(state) {
    return state.comp;
  },
  getReject(state) {
    return state.rejected;
  },
  getDrivers(state) {
    return state.drivers;
  },
  getCoordinates(state) {
    return state.coordinates;
  },
  getCenter(state) {
    return state.center;
  },
};
