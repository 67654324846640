<template>
    <div id="driver-details">
        <div id="profile">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="20" viewBox="0 0 11 20" fill="none" @click="back">
                <g clip-path="url(#clip0_847_20516)">
                  <path d="M11 2.85714L4.125 10L11 17.1429L9.625 20L2.38419e-07 10L9.625 -9.53673e-07L11 2.85714Z" fill="#413F3F"/>
                </g>
                <defs>
                  <clipPath id="clip0_847_20516">
                    <rect width="11" height="20" fill="white" transform="matrix(-1 0 0 -1 11 20)"/>
                  </clipPath>
                </defs>
              </svg>
            <h2>Manage Driver</h2>
        </div>
        <img :src="Image" :alt="value.name" id="profile-image" @error="handleImageError">
        <h2 id="profile-name">{{ value.name }}</h2>
        <div id="general-info">
            <div class="entry">
                <h2 class="key">Contact Number: </h2>
                <h2 class="value">{{ value.phone}}</h2>
            </div>
            <div class="entry">
                <h2 class="key">Email:</h2>
                <h2 class="value">{{ value.email}}</h2>
            </div>
            <div class="entry">
                <h2 class="key">DOB:</h2>
                <h2 class="value" v-if="value.dob">{{ value.dob.split('T')[0] }}</h2>
                <h2 class="value" v-else>----</h2>
            </div>
            <div class="entry">
                <h2 class="key">Vehicle Type:</h2>
                <h2 class="value">{{ value.vehicle_type}}</h2>
            </div>
            <div class="entry">
                <h2 class="key">Registration Number:</h2>
                <h2 class="value">{{ value.registration_number}}</h2>
            </div>
            <div class="entry">
                <h2 class="key">Vehicle Details:</h2>
                <h2 class="value">{{ value.vehicle_details}}</h2>
            </div>
            <div class="entry">
                <h2 class="key">Address:</h2>
                <h2 class="value">{{ value.address}}</h2>
            </div>
        </div>
        <!-- <h2 id="legal">Driver Legal Details</h2>
        <div id="legal-info">
            <div class="entry">
                <h2 class="key">National Insurance Certificate:</h2>
                <a :href="value.driver_national_insurance" class="driver-link">certificate.pdf</a>
            </div>
            <div class="entry">
                <h2 class="key">Drivers Insurance Certificate: </h2>
                <a :href="value.vehicle_insurance" class="driver-link">certificate.pdf</a>
            </div>
            <div class="entry">
                <h2 class="key">Drivers license:</h2>
                <a :href="value.driver_license" class="driver-link">license.pdf</a>
            </div>
        </div> -->
        <div id="buttons">
            <div class="button-1" @click="accept">Accept</div>
            <div class="button-2" @click="reject">Reject</div>
        </div>
    </div>
</template>

<script>
export default {
    props:['value'],
    data(){
    return{
        image:this.value
    }
},
computed:{
    Image(){
        return "https://firebasestorage.googleapis.com/v0/b/cappy-notes.appspot.com/o/default-profile.png?alt=media&token=d541afc8-9418-4e98-b472-eae4d9748786" //need to add this later
    }
},
    methods:{
        back(){
            this.$router.go(-1)
        },
        async accept(){
           let status = await this.$store.dispatch('driver/acceptDriver',{id:this.value._id})
           if(status==200){
            this.$router.push({name:'manageDrivers'})
           }
           else if(status==500){
            alert("Something went wrong at our end ")
           }
           else{
            alert('failed to verify driver')
           }
        },
        reject(){
            this.$store.commit('driver/setRejected',{value:true})
        },
        handleImageError() {
      // If the image fails to load, set the source to the default image
      this.image = "https://firebasestorage.googleapis.com/v0/b/cappy-notes.appspot.com/o/default-profile.png?alt=media&token=d541afc8-9418-4e98-b472-eae4d9748786"
    }
    }
}
</script>

<style scoped>
#driver-details{
    padding-bottom:4vh;
    overflow-x:hidden;
    overflow-y:auto;
}
#driver-details::-webkit-scrollbar{
    display: none;
}
#profile{
    display: flex;
    align-items: center;
    margin-left:1vw;
    margin-top:2vh;
}
#profile svg{
    margin-right:2vw;
    cursor: pointer;
}
#profile-image{
    width:100px;
    height:100px;
    border-radius: 100px;
    margin-left:1.5vw;
    margin-top:1vh;
    margin-bottom:0px;
}
#profile-name{
    color: var(--Brand-color-2, #413F3F);
font-family: Inter;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.307px;
margin-top:4px;
margin-left:1.5vw;
}
#legal{
    color: var(--Brand-color-2, #413F3F);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.307px;
    margin-top:6vh;
    margin-left:1.5vw;

}
#general-info,
#legal-info{
    padding-left:1.5vw;
    margin-top:3.5vh
}
.detail{
    margin-top:15px;
    margin-bottom:15px;
    color: #1c1c1c;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.307px;
}
.detail-value{
    color: #413F3F;
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.307px;
margin-left:1vw;
}
.driver-link{
    color: #25AD34;
font-family: Inter;
font-size: 18px;
font-style: italic;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
text-decoration-line: underline;
margin-left:1vw;
cursor: pointer;
}
#buttons{
    margin-top:6.5vh;
    padding-left:3vw;
    display: flex;
    
}
.button-1{
    border-radius: 37px;
background: #25AD34;
width:18vw;
display: flex;
align-items: center;
justify-content: center;
height:51px;
color: var(--light-text-color, #FFF);
text-align: center;
font-family: Montserrat;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 100% */
letter-spacing: 0.2px;
cursor: pointer;
}
.button-2{
    margin-left:3vw;
    width:18vw;
display: flex;
align-items: center;
justify-content: center;
height:51px;
border-radius: 37px;
border: 2px solid var(--brand-color, #25AD34);
background: var(--White, #FFF);
color: var(--brand-color, #25AD34);
text-align: center;
font-family: Montserrat;
font-size: 22px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 100% */
letter-spacing: 0.2px;
cursor: pointer;
}
.entry{
    display: grid;
    grid-template-columns: 12vw 16vw;
    margin-top:15px;
    margin-bottom:15px;
}
.key{
    color: #413F3F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.305px;
    margin-top:0px;
    margin-bottom:0px;
}
.value{
    float:right;
    margin-right:5vw;
    color: #413F3F;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.305px;
    text-align: start;
    margin-top:0px;
    margin-bottom:0px;
}
.driver-link{
    color: #25AD34;
font-family: Inter;
font-size: 16px;
font-style: italic;
font-weight: 500;
line-height: normal;
letter-spacing: 0.2px;
text-decoration-line: underline;
margin-left:1vw;
cursor: pointer;
}
</style>