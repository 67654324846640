<template>
    <div id="search">
        <div id="search-header">
            <h2>Hello {{ Name }}</h2>
            <svg xmlns="http://www.w3.org/2000/svg" width="177" height="8" viewBox="0 0 177 8" fill="none">
                <path d="M0.246559 4.01721L4.08203 7.85268L7.9175 4.01721L4.08203 0.181739L0.246559 4.01721ZM177.001 3.35289H4.08203V4.68154H177.001V3.35289Z" fill="#25AD34"/>
              </svg>
        </div>
        <div id="search-bar">
            <div id="search-input">
                <input type="text" :placeholder="Placeholder" v-model="value" id="input-bar" @keydown="moveToNextInput($event)">
                <div id="magnify">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none" @click="search">
                        <circle cx="9.24445" cy="9.37595" r="7.63226" stroke="#25AD34" stroke-width="2.57595"/>
                        <path d="M13.9824 15.0913L20.184 21.2935" stroke="#25AD34" stroke-width="2.57595"/>
                      </svg>
                </div>
            </div>
            <!-- <svg xmlns="http://www.w3.org/2000/svg" width="67" height="68" viewBox="0 0 67 68" fill="none" id="profile">
                <g filter="url(#filter0_d_754_1221)">
                  <circle cx="33.4375" cy="31.4375" r="26.4375" fill="#F6F6F6" fill-opacity="0.945098" shape-rendering="crispEdges"/>
                </g>
                <g opacity="0.5">
                  <path d="M33.367 36.4199C27.1901 36.4199 21.9141 37.3418 21.9141 41.0295C21.9141 44.7185 27.1572 45.673 33.367 45.673C39.5438 45.673 44.8199 44.7524 44.8199 41.0634C44.8199 37.3743 39.5782 36.4199 33.367 36.4199Z" fill="#25AD34"/>
                  <path opacity="0.4" d="M33.3661 32.9072C37.5737 32.9072 40.945 29.7143 40.945 25.7324C40.945 21.7505 37.5737 18.5577 33.3661 18.5577C29.1598 18.5577 25.7871 21.7505 25.7871 25.7324C25.7871 29.7143 29.1598 32.9072 33.3661 32.9072Z" fill="#25AD34"/>
                </g>
                <path d="M33.367 36.4199C27.1901 36.4199 21.9141 37.3418 21.9141 41.0295C21.9141 44.7185 27.1572 45.673 33.367 45.673C39.5438 45.673 44.8199 44.7524 44.8199 41.0634C44.8199 37.3743 39.5782 36.4199 33.367 36.4199Z" fill="#25AD34"/>
                <path opacity="0.4" d="M33.3661 32.9072C37.5737 32.9072 40.945 29.7143 40.945 25.7324C40.945 21.7505 37.5737 18.5577 33.3661 18.5577C29.1598 18.5577 25.7871 21.7505 25.7871 25.7324C25.7871 29.7143 29.1598 32.9072 33.3661 32.9072Z" fill="#25AD34"/>
                <defs>
                  <filter id="filter0_d_754_1221" x="0.221154" y="0.932692" width="66.4327" height="66.4327" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="2.71154"/>
                    <feGaussianBlur stdDeviation="3.38942"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_754_1221"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_754_1221" result="shape"/>
                  </filter>
                </defs>
              </svg> -->
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
    data(){
        return{
            value:'',
            placeholder:'Search Order ID'
        }
    },
    computed:{
        Name(){
            try{
                return Cookies.get('name')
            }
            catch(err){
                return 'admin'
            }
        },
        Placeholder(){
            return this.placeholder
        }
    },
    methods:{
        async search(){
            if(this.value!=''){
                let res =await this.$store.dispatch('dashboard/search',{value:this.value})
                if(res == "2" || !res){
                    {
                        this.placeholder = "Driver not found"
                        this.value = ""
                    }
                }
                else if(res == '1'){
                    this.placeholder = "Driver not assigned yet"
                    this.value = ""
                }
                else{
                    this.$store.commit('user/setCoordinates',{latitude:res.lat,longitude:res.lng})
                    this.value=''
                }
            }
        },
        async moveToNextInput(event) {
      if (event.key === 'Enter') {
          await this.search()
      }
              }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Libre+Bodoni&family=Montserrat:wght@400;500&display=swap');

#search-header{
    display: flex;
    padding-left:3vw;
    align-items: center;
}
#search-header h2{
    color: #333;
font-family: Inter;
font-size: 25.974px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.375px;
}
#search-header svg{
    margin-left:4px;
    margin-top:-2px;
}
#search-bar{
    margin-top:2vh;
    margin-left:3vw;
    display: flex;
}
#search-input{
    border-radius: 51.519px;
border: 1.288px solid var(--brand-color, #25AD34);
background: #FFF;
box-shadow: 0px 1.28797px 2.57595px 0px rgba(0, 0, 0, 0.15);
height: 52px;
width:25vw;
padding-left:0px;
padding-right:2vw;
padding-top:0px;
margin-top:0px;
display: flex;
}
#input-bar,
#input-bar::selection,
#input-bar:active{
    background: transparent;
    outline:none;
    border:none;
    width:20vw;
    height:52px;
    margin:0px;
    padding-left:1vw;
    color: rgba(0, 0, 0, 0.752);
    font-family: Montserrat;
    font-size: 17.032px;
    font-style: normal;
    font-weight: 500;
    line-height: 52px; /* 171.429% */
    letter-spacing: 0.258px;
}
#input-bar::placeholder{
    color: #25AD34;
font-family: Montserrat;
font-size: 17.032px;
font-style: normal;
font-weight: 500;
line-height: 52px; /* 171.429% */
letter-spacing: 0.258px;
}
#magnify{
    margin-left:2.5vw;
    padding-top:15px;
}
#profile{
    cursor:pointer;
    margin-left:1vw;
    margin-top:-4px;
}
</style>