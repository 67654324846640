<template>
    <div id="forgot-password">
        <component :is="component"></component>
    </div>
</template>

<script>
import ValuePage from '../../components/onboarding/forgot-password/forgot-email.vue'
import ResetPassword from '../../components/onboarding/forgot-password/reset-password.vue'
import Cookies from 'js-cookie'
export default {
    components:{ValuePage,ResetPassword},
    computed:{
        component(){
            let page = this.$store.getters['user/getForgotPage']
            if(page == 0) return 'ValuePage'
            else if(page==1) return 'ResetPassword'
            else return 'ValuePage'
        }
    },
    mounted(){
        if(Cookies.get('token')){
              this.$router.push({name:'index'})
        } 
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
</style>