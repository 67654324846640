import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";
export default {
  namespaced: true,
  state() {
    return {
      suspend: false,
      comp: "0", // 0 for suspend ,1 for delete
      outlets: [],
      center: {},
    };
  },
  mutations,
  getters,
  actions,
};
